.footer {
  width: 100%;
  height: 181px;
  background: #000;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
  position: relative;
  z-index: 1;
  .one_nft {
    text-align: center;
    font-size: 12px;
    margin: 30px 0;
  }
  .links {
    display: flex;
    justify-content: center;
    gap: 47px;
    img {
      height: 18px;
    }
  }
  .nft_logo {
    width: 120px;
    margin-top: 20px;
  }
}

@media screen and (min-width: 768px) {
  .footer {
    .one_nft {
      font-size: 14px;
    }
    .nft_logo {
      width: 164px;
    }
  }
}

.header {
  width: 100%;
  height: 61px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;

  .header_top {
    height: 58px;
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #000;
    .logo {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      font-weight: bold;
      color: #fff;

      .logo_pc {
        display: none;
      }
      .logo_mobile {
        width: 40px;
      }
    }
    .header_left {
      height: 100%;
      display: flex;
      align-items: center;
      .header_link {
        display: none;
      }
      .ma_account {
        display: none;
        margin: 0 33px;
        color: #fff;
        font-size: 20px;
        text-decoration: none;
        > img {
          width: 25px;
        }
      }
      .lang_box_pc {
        height: 100%;
        display: none;
        position: relative;

        .lang_box_item {
          height: 100%;
          display: flex;
          align-items: center;
        }
        .lang {
          font-size: 20px;
          text-align: center;
          color: #fff;
          > img {
            width: 30px;
          }
        }
        .lang_list {
          position: absolute;
          top: 100%;
          right: -20px;
          padding: 10px;
          background: #fff;
          border-radius: 5px;
          transition: all 0.2s ease-in;
          color: #000;
          margin: 0;
          .lang_list_item {
            display: flex;
            align-items: center;
            height: 20px;
            width: 60px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;
            > img {
              width: 10px;
              margin-right: 0;
            }
            .lang_list_item_img_active {
              display: block;
            }
            .lang_list_item_img {
              display: none;
            }
          }
        }
        .lang_list_show {
          transform: scale(1);
        }
        .lang_list_hide {
          transform: scale(0);
        }
      }
      .lang_box {
        position: relative;
        .lang_box_item {
          width: 100%;
          display: flex;
          align-items: center;
        }
        .lang {
          flex: 1;
          min-width: 130px;
          font-size: 17px;
          text-align: center;
          color: #fff;
        }
        .lang_list {
          position: absolute;
          top: 50%;
          left: 50%;
          padding: 10px;
          background: #fff;
          border-radius: 5px;
          transition: all 0.2s ease-in;
          color: #000;
          .lang_list_item {
            display: flex;
            align-items: center;
            height: 20px;
            width: 60px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;
            > img {
              width: 10px;
              margin-right: 0;
            }
            .lang_list_item_img_active {
              display: block;
            }
            .lang_list_item_img {
              display: none;
            }
          }
        }
        .lang_list_show {
          transform: translate(-50%, -50%) scale(1);
        }
        .lang_list_hide {
          transform: translate(-50%, -50%) scale(0);
        }
      }

      .more {
        margin-left: 0.5rem;
        > img {
          width: 16px;
          height: 15px;
        }
        .menu_list {
          width: 220px;
          position: absolute;
          top: 0;
          right: 0;
          color: #fff;
          transition: all 0.2s ease-in;
          background: url("../../src/assets/img/header/menubg.png");
          background-size: 100% 100%;
          padding: 0;
          padding-top: 19px;
          transform-origin: 0 0 0;
          -webkit-transform-origin: 100% 0 0;
          margin: 0;
          > li {
            height: 40px;
            display: flex;
            align-items: center;
            padding: 0 23px;
            a {
              display: flex;
              align-items: center;
              width: 100%;
            }
            img {
              display: block;
              width: 18px;
              margin-right: 20px;
            }
            .name {
              flex: 1;
              color: white;
              text-decoration: none;
              min-width: 130px;
              font-size: 17px;
              text-align: center;
            }
          }
          .no_app_download {
            cursor: not-allowed;
            .name {
              color: #8f8d8d;
            }
          }
          .line {
            display: block;
            width: 100%;
            height: 2px;
          }
        }
        .menu_list_show {
          transform: scale(1);
        }
        .menu_list_hide {
          transform: scale(0);
        }
        .check_in_box {
          display: flex;
          align-items: center;
          width: 100%;
          > img {
            display: block;
            width: 18px;
            margin-right: 20px;
          }
          .check_in_btn {
            color: white;
            text-decoration: none;
            width: 130px;
            font-size: 17px;
            text-align: center;
          }
          .checked_in_btn {
            color: #8f8d8d;
          }
        }
      }
      .check_in {
        display: none;
        width: 80px;
        height: 30px;
        background: url("../assets/img/header/check_in.png");
        background-size: 100% 100%;
        color: #fff;
        text-align: center;
        line-height: 30px;
        margin-left: 10px;
        cursor: pointer;
        font-size: 12px;
        font-weight: 500;
        position: relative;
        &:active {
          background: #021a27;
        }
        .check_in_dian {
          position: absolute;
          top: -3px;
          right: 0;
          width: 10px;
          height: 10px;
          background-color: #f83d2c;
          border-radius: 50%;
          border: 1px solid #f6f6f6;
        }
      }
      .checked_in {
        background: #8f8d8d;
        border-radius: 15px;
        &:active {
          background: #8f8d8d;
          border-radius: 15px;
        }
      }
      .check_in_mobile {
        display: block;
      }
    }
  }
  .header_bottom_img {
    width: 100%;
    height: 3px;
  }
}
.check_in_modal {
  width: 90% !important;
  .ant-modal-content {
    padding: 0;
    border-radius: 0;
    background: transparent;
    .ant-modal-body {
      height: auto;
      background: url("../assets/img/header/checkbg.png") no-repeat;
      background-size: 100% 100%;
      position: relative;
      color: #fff;
      padding-top: 50px;
      .check_in_modal_tit {
        width: 216px;
        height: 39px;
        position: absolute;
        top: -10px;
        left: 50%;
        transform: translateX(-50%);
        background: url("../assets/img/header/check_in_t_bg.png");
        background-size: 100% 100%;
        text-align: center;
        line-height: 39px;
        font-size: 18px;
      }
      .check_in_modal_tit_bg {
        width: 218px;
        height: 30px;
        background: #492648;
        position: absolute;
        top: -10px;
        left: 50%;
        transform: translateX(-50%);
        z-index: -1;
        border-radius: 5px;
      }
      .check_in_CIS {
        width: 207px;
        margin-left: 50%;
        transform: translateX(-50%);
      }
      .check_in_modal_get {
        margin-top: 25px;
        font-size: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        vertical-align: middle;

        .check_in_modal_get_num {
          font-size: 40px;
          font-weight: bold;
          padding: 0 10px;
          line-height: 40px;
        }
      }
      .check_in_modal_bom {
        width: 100%;
        // height: 240px;
        margin-top: 36px;
        background: url("../assets/img/header/check_in_bom_bg.png") no-repeat -14px -13px;
        background-size: 108% 116%;
        position: relative;
        padding-top: 70px;
        padding-bottom: 28px;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 0 0 15px 15px;

        .check_in_modal_crown_one {
          width: 153px;
          height: 27px;
          background: url("../assets/img/header/linel.png");
          background-size: 100% 100%;
          position: absolute;
          top: 0;
          left: 0;
        }
        .check_in_modal_crown_two {
          width: 54px;
          height: 32px;
          background: url("../assets/img/header/ci_crown.png");
          background-size: 100% 100%;
          position: absolute;
          top: 0;
          left: 50%;
          transform: translateX(-50%);
        }
        .check_in_modal_crown_three {
          width: 153px;
          height: 27px;
          background: url("../assets/img/header/liner.png");
          background-size: 100% 100%;
          position: absolute;
          top: 0;
          right: 0;
        }
        .check_in_modal_btn {
          width: 213px;
          height: 46px;
          line-height: 46px;
          background: linear-gradient(
            270deg,
            #ffe7ff 0%,
            #f7dec2 0%,
            #20aee7 99%
          );
          border-radius: 15px;
          text-align: center;
          font-size: 20px;
          color: #321313;
          cursor: pointer;
        }
        .check_in_modal_link {
          margin-top: 28px;
          color: #fff;
          font-size: 12px;
          border-bottom: 1px solid #5ae4f4;
        }
        .check_in_modal_juan_two {
          width: 29px;
          position: absolute;
          top: 43px;
          left: 73%;
        }
      }
      .check_in_modal_juan_one {
        width: 54px;
        position: absolute;
        top: 0px;
        left: -15px;
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .header {
    .header_top {
      padding: 0 20px;
      .logo {
        > img {
          width: 120px;
        }
        .logo_pc {
          display: block;
        }
        .logo_mobile {
          display: none;
        }
      }
      .header_left {
        .ma_account {
          display: block;
        }
        .lang_box_pc {
          display: block;
        }
        .more {
          display: none;
        }
        .header_link {
          display: flex;
          a {
            margin-right: 18px;
            color: #fff;
            font-size: 20px;
            text-decoration: none;
            padding: 0 15px;
            border-radius: 15px;
          }
          .active_link {
            background: linear-gradient(0deg, #015eea 0%, #00c0fa 100%);
          }
        }
        .check_in {
          display: block;
          width: 156px;
          height: 45px;
          line-height: 45px;
          margin-right: 33px;
          font-size: 20px;
          &:active {
            border-radius: 23px;
          }
          .check_in_dian {
            position: absolute;
            top: -3px;
            width: 15px;
            height: 15px;
          }
        }
        .checked_in {
          border-radius: 23px;
        }
        .check_in_mobile {
          display: none;
        }
      }
    }
  }

  .check_in_modal {
    width: 520px !important;
    .ant-modal-content {
      .ant-modal-body {
        height: 630px;
        padding-top: 86px;
        border-radius: 15px;
        .check_in_modal_tit {
          width: 353px;
          height: 64px;
          top: -20px;
          line-height: 64px;
          font-size: 29px;
        }
        .check_in_modal_tit_bg {
          width: 364px;
          height: 64px;
          top: -19px;
          border-radius: 11px;
        }
        .check_in_CIS {
          width: 339px;
        }
        .check_in_modal_get {
          margin-top: 40px;
          font-size: 28px;

          .check_in_modal_get_num {
            font-size: 68px;
          }
        }
        .check_in_modal_bom {
          margin-top: 60px;
          background: url("../assets/img/header/check_in_bom_bg.png") no-repeat -20px -12px;
          background-size: 108% 113%;
          position: relative;
          padding-top: 110px;
          padding-bottom: 28px;
          display: flex;
          flex-direction: column;
          align-items: center;
          border-radius: 0 0 15px 15px;

          .check_in_modal_crown_one {
            width: 250px;
            height: 44px;
          }
          .check_in_modal_crown_two {
            width: 87px;
            height: 52px;
          }
          .check_in_modal_crown_three {
            width: 250px;
            height: 44px;
          }
          .check_in_modal_btn {
            width: 349px;
            height: 76px;
            line-height: 76px;
            border-radius: 24px;
            font-size: 32px;
          }
          .check_in_modal_link {
            font-size: 14px;
          }
          .check_in_modal_juan_two {
            width: 47px;
            top: 67px;
            left: 76%;
          }
        }
        .check_in_modal_juan_one {
          width: 89px;
          position: absolute;
          top: 0px;
          left: -30px;
        }
      }
    }
  }
}
